import TileLayer from 'ol/layer/Tile'
import TileWMS from 'ol/source/TileWMS'
import useMap from 'hooks/use-map'
import { get } from 'lodash-es'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { transformExtent } from 'ol/proj'

export default function MapStaticBoundaryLayer(props) {
  const {
    fieldLayerName = '',
    bbox,
    mapRef = 'map',
    tenantId,
    layerZIndex,
  } = props

  const [params, setParams] = useState({
    tiled: true,
    WIDTH: 600,
    HEIGHT: 600,
    BBOX: bbox,
    SRS: 'EPSG:4326',
    viewparams: '',
  })

  let map = useMap(mapRef)

  const [source, setSource] = useState()
  const [layer, setLayer] = useState()
  const client = useSelector((state) => get(state, 'client.singleton'))
  const selectedYear = useSelector((state) => get(state, 'field.year'))
  const mapExtent = useSelector((state) => get(state, 'map.extent'))

  useEffect(() => {
    setParams({
      tiled: true,
      WIDTH: 600,
      HEIGHT: 600,
      BBOX: bbox,
      SRS: 'EPSG:4326',
      layers: fieldLayerName,
      viewparams: `tenant_id:${tenantId};year:${selectedYear ?? ''};client_id:${
        client?.id ?? ''
      }`,
    })
  }, [tenantId, client, selectedYear])

  useEffect(() => {
    const _source = new TileWMS({
      url: `${GEOSERVER_URL}/ows`,
      serverType: 'geoserver',
      params: params,
      transition: 0,
    })
    setSource(_source)
  }, [params])

  useEffect(() => {
    if (map && source && mapExtent) {
      const _layer = new TileLayer({
        source,
        zIndex: layerZIndex,
      })
      // this extent is the default extent of the customer's account
      // by setting this extent, the Openlayer map will not render tiles
      // outside this extent
      _layer.setExtent(transformExtent(mapExtent, 'EPSG:4326', 'EPSG:3857'))

      setLayer(_layer)
    }
  }, [map, source, mapExtent])

  useEffect(() => {
    if (layer) {
      map.addLayer(layer)
    }

    return () => {
      map.removeLayer(layer)
    }
  }, [layer])

  return null
}
